 //let idEditor = {
 //  name: "Редактиране",
 //  path: 'https://gis.vik-vratza.eu:9443/iD/#background=OSM&disable_features=project,project_approved&map=12.60/42.6090/23.0480',
  // target: true,
  //meta: { icon: "mapEditor" },
  // groups: ["TestMainGroup"],
 //}

//  let idEditor = {
//   name: "Редактиране",
//   path: 'https://gis.vik-pernik.eu/iD/#background=OSM&map=13.80/42.6127/23.0551',
//   target: true,
//   meta: { icon: "mapEditor" },
//   groups: ["TestMainGroup"],
//  }
let idEditor = {
    name: "Редактиране",
  //  path: 'https://aquagis.eu/iD/#background=OSM&map=13.20/43.2141/23.5675',
    path: 'https://gis.vik-pernik.eu/iD/#background=OSM&map=13.80/42.6127/23.0551',
    target: true,
    meta: { icon: "mapEditor" },
    groups: ["TestMainGroup"],
   }
//Shumen
//let idEditor = {
//  name: "Редактиране",
 // path: 'http://192.168.3.203/iD/#background=OSM&disable_features=water_objects,sewer_objects&map=13.40/43.2739/26.9377',
//  target: true,
//  meta: { icon: "mapEditor" },
//  groups: ["TestMainGroup"],
//}
export default idEditor;
