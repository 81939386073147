 let thematicMap = {
   name: "Tематични карти",
 //path: 'https://thematic-maps.vik-vratza.eu:9443/thematicmap',
  // path: process.env.VUE_APP_THEMATIC_MAPS,
   path: 'https://thematic-maps.vik-pernik.eu/thematicmap',
 //  path: 'https://thematic-maps.aquagis.eu/thematicmap',
  //path: 'https://thematic-maps.vik-ruse.com/thematicmap',
   target: true,
   meta: { icon_fa: "fas fa-map-marked-alt"},
   groups: ["TestMainGroup", "TestDummyGroup"],
 }

//let thematicMap = {
//  name: "Tематични карти",
//  path: 'https://thematic-maps.vik-pernik.eu/thematicmap',
//  target: true,
//  meta: { icon_fa: "fas fa-map-marked-alt" },
 // groups: ["TestMainGroup", "TestDummyGroup"],
//}

// let thematicMap = {
//   name: "Tематични карти",
//   path: 'https://thematic-maps.wik-stz.com/thematicmap',
//   target: true,
//   meta: { icon_fa: "fas fa-map-marked-alt" },
//   groups: ["TestMainGroup", "TestDummyGroup"],
// }

export default thematicMap;
